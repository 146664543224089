import { Link } from "react-router-dom";
import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container text-center" style={{display:'flex', justifyContent:'center', alignItems:'center',flexDirection:'column'}}>
    <div className="section-title">
          <h2>Galeria</h2>
        </div>
      
        <div  className="row" >
        <div className="col-lg-6 col-sm-6" style={{marginBottom:40}}>
          <div className=" shadow portfolio-em"  style={{display:'flex', justifyContent:'center', alignItems:'end'}}>
          <div className="" style={{display:'flex', justifyContent:'center', alignItems:'center',flexDirection:'column',backgroundColor:'white',padding:20,minWidth:"100%",borderRadius: '0 0 8px 8px'}}>
          
          <h3>ESTRUTURAS METÁLICAS</h3>
        
          <Link to={'/gallery-em'} className="btn btn-custom btn-lg page-scroll">
            ver galeria
          </Link>{" "}

          </div>
          </div>
          </div>
          <div className="col-lg-6 col-sm-6">
          <div className=" shadow portfolio-hidro " style={{display:'flex', justifyContent:'center', alignItems:'end'}}>
          <div className="" style={{display:'flex', justifyContent:'center', alignItems:'center',flexDirection:'column',backgroundColor:'#ffffff',padding:20,minWidth:"100%",borderRadius: '0 0 8px 8px'}}>

          <h3 style={{textAlign:"justify"}}>EQUIPAMENTOS HIDROMECÂNICOS</h3>
      
      
          <Link to={'/gallery-hidro'} className="btn btn-custom btn-lg page-scroll" >
            ver galeria
          </Link>{" "}
          </div>
          </div>
          </div>
        </div>
      
      </div>
    </div>
  );
};
