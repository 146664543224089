import { Image } from "./image";
import React from "react";
import { Navigation } from "./navigation";
import BtnContact from "./btn-budget";


export const GalleryEM = (props) => {
  return (
  <>
  <Navigation />

    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data?.Paragraph1}</h2>
          <p>
          Confira abaixo algumas das nossas obras e projetos realizados.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
   
            <br />
            {props.data
              ? props.data.E_M.map((d, i) => (
                <div
                key={`${d.title}-${i}`}
                className="col-sm-6 col-md-4 col-lg-4"
                >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                      />
                  </div>
                ))
                : "Loading..."}
          </div>
        </div>
        <BtnContact />
      </div>
    </div>
                </>
  );
};
