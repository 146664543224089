
import React from "react";
import { Link } from "react-router-dom";

export const Features = (props) => {
  
  return (
    <div id="features" >
    <div className="container text-center" style={{display:'flex', justifyContent:'center', alignItems:'center',flexDirection:'column'}}>
    <div className="section-title">
          <h2>Nossos Serviços</h2>
        </div>
      
        <div  className="row" >
        <div className="col-lg-6 col-sm-6" style={{marginBottom:40}}>
          <div className=" shadow features-em"  style={{display:'flex', justifyContent:'center', alignItems:'start',paddingTop:30,paddingLeft:30}}>
          <div className="" style={{display:'flex', justifyContent:'center', alignItems:'start',flexDirection:'column',backgroundColor:'white',padding:20}}>
          
          <h3>ESTRUTURAS METÁLICAS</h3>
          <h4 style={{textAlign:"justify",color:'#00000094'}}>Fabricação e montgem de estruturas metálicas</h4>
        
          <Link to={'/services-em'} className="btn btn-custom btn-lg page-scroll">
            Saiba Mais
          </Link>{" "}

          </div>
          </div>
          </div>
          <div className="col-lg-6 col-sm-6">
          <div className=" shadow features-hidro " style={{display:'flex', justifyContent:'center', alignItems:'end',paddingBottom:30,paddingRight:30}}>
          <div className="" style={{display:'flex', justifyContent:'center', alignItems:'start',flexDirection:'column',backgroundColor:'#ffffff',padding:20}}>

          <h3 style={{textAlign:"justify"}}>EQUIPAMENTOS <br />HIDROMECÂNICOS</h3>
          <h4 style={{textAlign:"justify",color:'#00000094'}}>Fabricação, recuperação e montagem de equipamentos hidromecânicos.</h4>
      
          <Link to={'/services-hidro'} className="btn btn-custom btn-lg page-scroll" >
            Saiba Mais
          </Link>{" "}
          </div>
          </div>
          </div>
        </div>
      
      </div>
    </div>
  );
};
