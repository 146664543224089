
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { Services_em } from "./components/services_e_m";
import { Services_hidro } from "./components/services_hidro";
import JsonData from "./data/data.json";
import { useEffect, useState } from "react";
import { GalleryEM } from "./components/gallery_em";
import { GalleryHidro } from "./components/gallery_hidro";


const Router = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
            <Route path="*" element={<App />} />
            <Route path="/services-em" element={<Services_em data={landingPageData.Services_em} />} />
            <Route path="/services-hidro" element={<Services_hidro data={landingPageData.Services_hidro} />} />
            <Route path="/gallery-em" element={<GalleryEM data={landingPageData.Gallery} />} />
            <Route path="/gallery-hidro" element={<GalleryHidro data={landingPageData.Gallery} />} />
        </Routes>

      </BrowserRouter>
    </>

  );
};

export default Router;
